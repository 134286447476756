import React , { lazy } from 'react'
import { useState, useEffect } from "react"
import Config from '../../../config';
import { useHistory,useParams } from "react-router-dom";
 const Popup = lazy(() => import('../../widgets/DeletePop.js'))
 const Header= lazy(() => import("../../widgets/Header.js"));
 const Asidebar =  lazy(() => import('../../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../../widgets/Footer.js'))
 const Jsfile = lazy(() => import('../../widgets/Jsfile.js'))

const AddCategories = (props) => {
const {id} = useParams()
 
//  const [toast, setToast] = useState(true);
 const [data ,setData] = useState([]);
 const catData =  () =>{
   
    Config.get(`/admin/doc-category-detail?_id=${id}`)
    .then((res) => {
     console.log(res);
        if (res.status) {
        setData(res.data)

      }else{
        Config.showToast("Please try after some time.");
      }
    })
    .catch((err) => {
      Config.showToast("Please try after some time.");
    });

  }

    const setIdinLocal = (id)=>{
    localStorage.setItem("DocCat",id);
    localStorage.setItem("deleteType","docCat");
   
    }
   


    

  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/assets/js/main.js';   //(This is external js url)   
    document.body.appendChild(script);
    if(props.type== "Edit"){
      catData()
      document.getElementById("deleteButton").style.display = "block"
    }
  }, [])

  const history = useHistory();

  // add edit data
    async function AddPlan(e) {
          e.preventDefault();
        
             var name = e.target.name.value, 
             icon = e.target.icon.value,
             code = e.target.code.value
   

        if(props.type == "Add"){
         var data = {name,icon,code};
            Config.post('/admin/doc-category',data)
              .then((res) => {
              console.log(res);
                if (res.status) {
                  history.push("/DocCategory");
                  }else{
                  Config.showToast("somthing wrong");
                  }
                })
              .catch((err) => {
                Config.showToast("Please try after some time.");
              });

            }else {
              
          // call edit api 
          var data = {_id:id,name,code,icon};

                Config.put('/admin/doc-category',data)
                .then((res) => {
                  console.log(res);
                  if (res.status) {
                     
                    history.push("/DocCategory");
                    }else{
                    Config.showToast("somthing wrong ");
                    }
                  })
                .catch((err) => {
                  Config.showToast("Please try after some time.");
                });



         }

      }
  


  return (
    <>
      <Jsfile />
      <Header />
 <main id="main" className="main">
  <Popup />

  <section className="section">
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{props.type} Category </h5>
            {/* Multi Columns Form */}

          

            <form className="row g-3" method='post' onSubmit={AddPlan}>
              <div className="col-md-4">
                <label htmlFor="inputName5" className="form-label">
                 Name  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="name" onChange={(e) => setData(e.target.value)} value={data.name}  required/>
              </div>
              <div className="col-md-4">
                <label htmlFor="inputName5" className="form-label">
                 I-Con  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="icon" onChange={(e) => setData(e.target.value)} value={data.code} required/>
              </div>

              <div className="col-md-4">
                <label htmlFor="inputName5" className="form-label">
                 Code  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="code" onChange={(e) => setData(e.target.value)} value={data.code} required/>
              </div>
         
         
       
        
            <div className="text-center">
                  
                  <button type="submit" className="btn submit-btn1">
                  {props.type== "Edit" ? "Update": "Submit" }
                  </button>
 
                  <span onClick={()=>{ history.push("/DocCategory");}} className="btn  submit-btn2">
                   Go Back</span>
             

                  <span className="btn  submit-btn4" style={{float:"right", display:"none"}} data-bs-toggle="modal" 
                   id ="deleteButton"
                   onClick={()=>{setIdinLocal(data._id)}} data-bs-target="#verticalycentered"
                   >Delete</span>
              
              </div>
            </form>
            {/* End Multi Columns Form */}
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
 
      <Asidebar />
      <Footer /> 
    
</>

 )
}

export default AddCategories
